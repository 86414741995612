<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-left py-7 color-head-card mb-3">
          <router-link
            to="/"
            class="ml-5 d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="40px"
              max-width="100px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <!-- <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2> -->
          </router-link>
          <v-card-text>
            <p class="text-2xl font-weight-semibold text--primary mb-0">
              Recuperar contraseña
            </p>
            <p class="mb-0 color-card-text">
              Introduzca un usuario valido
            </p>
          </v-card-text>
        </v-card-title>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="forgotForm"
            @submit.prevent="handleFormSubmit"
          >
            <v-text-field
              name="usuario"
              v-model="usuario"
              outlined
              label="Usuario"
              placeholder="usuario"
              hide-details
              class="mb-4"
              :rules="[validators.required]"
            ></v-text-field>

            <v-btn
              block
              color="primary"
              type="submit"
              class="mt-6"
              :loading="loading"
              :disabled="loading"
            >
              Recuperar contraseña
            </v-btn>
          </v-form>
        </v-card-text>

        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            He Recordado mi contraseña
          </span>
          <router-link :to="{name:'auth-login'}">
            Volver al inicio de sesión
          </router-link>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar
      v-model="isFormSubmittedSnackbarVisible2"
      right
      top
      :color="colorSnackbar"
    >
      {{ textSnackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="isFormSubmittedSnackbarVisible2 = false"
        >
          <v-icon>{{ icons.mdiCloseThick }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiCloseThick } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { required, emailValidator } from '@core/utils/validation'
import { useRouter } from '@core/utils'
import { UserServices } from '../service/userService'

export default {
  setup() {
    const forgotForm = ref(null)
    const isPasswordVisible = ref(false)
    const { router } = useRouter()
    const loading = ref(false)
    const errorMessages = ref([])

    const usuario = ref('')
    const isFormSubmittedSnackbarVisible2 = ref(false)
    const colorSnackbar = ref('')
    const textSnackbar = ref('')

    const handleFormSubmit = () => {
      const isFormValid = forgotForm.value.validate()

      if (!isFormValid) return

      loading.value = true
      UserServices.recuperar_Clave(usuario)
        .then(resp => {
          console.log(resp)
          if (resp.data.statusCode === 200 || resp.data.statusCode === 201) {
            isFormSubmittedSnackbarVisible2.value = true
            textSnackbar.value = 'Se ha enviado un correo con la nueva contraseña'
            colorSnackbar.value = 'success'

            setTimeout(() => router.push('/inicio_sesion'), 3000)
          }
          if (resp.data.statusCode === 400 || resp.data.statusCode === 404) {
            isFormSubmittedSnackbarVisible2.value = true
            textSnackbar.value = resp.data.message
            colorSnackbar.value = 'error'
            loading.value = false
          }
        })
        .catch(error => {
          console.log(error)

          // TODO: Next Update - Show notification
          // errorMessages.value = error.data.message
          isFormSubmittedSnackbarVisible2.value = true
          textSnackbar.value = error.data.message
          colorSnackbar.value = 'error'
          loading.value = false
        })
    }

    return {
      forgotForm,

      handleFormSubmit,
      isPasswordVisible,
      usuario,
      isFormSubmittedSnackbarVisible2,
      colorSnackbar,
      textSnackbar,
      errorMessages,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logoLogin,

      icons: {
        mdiChevronLeft,
        mdiCloseThick,
      },
      validators: {
        required,
        emailValidator,
      },
      loading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
.color-head-card {
  // background-color: rgb(20 20 20 / 25%);
  background: #56ccf2; /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #2f80ed, #56ccf2); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #2f80ed,
    #56ccf2
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.color-card-text {
  color: #156bb6;
}
</style>
